.App {
  text-align: center;
  min-height: 100vh;
  -webkit-transition: background-color 2s;
  transition: background-color 2s;
}

.App-header {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  -webkit-transition: background-color 2s;
  transition: background-color 2s;
}

.masthead {
  flex: 1;
  justify-content: flex-start;
  font-family: 'Muli', sans-serif;
  font-weight: 700;
}

.main-container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 38rem;
  text-align: left;
}

.blue-text {
  color: #007bff;
}

.gray-text {
  color: #585858;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.about {
  display: flex;
  flex-direction: column;
}

.connect {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #585858;
}

.black-text {
  color: #1A1A1A;

}

.gray-text {
  color: #A1A3A6;
}

.orange-text {
  color: #ff8400;
}

small {
  color: #585858;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

h3 {
  color: #007bff;
  font-family: 'Muli', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

p {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #585858;
}

a {
  color: #007bff;
  text-decoration: none;
  position: relative;
}

/* #glow {
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow:
  inset 0 0 10px #fff,
  inset 20px 0 80px #171717,
  inset -20px 0 80px #171717,
  inset 20px 0 300px #171717,
  inset -20px 0 300px #171717,
  0 0 50px #fff,
  -10px 0 80px #171717,
  10px 0 80px #171717;
} */

#work:hover {
  text-decoration: underline;
  font-weight: 700;
  color: #ff8400;
}

/* Rahul blue underline */
#underline {
  color: #007bff;
  text-decoration: none;
  position: relative;
}

#underline:link {
  text-decoration: none;
  color: #007bff;
}

#underline:after {
  border-radius: 2em;
  border-top: .2em solid #007bff;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline:hover:after {
  right: 0;
}

/* Story blue underline */
#underline-story-blue {
  color: #0095AD;
  text-decoration: none;
  position: relative;
}

#underline-story-blue:link {
  text-decoration: none;
  color: #0095AD;
}

#underline-story-blue:after {
  border-radius: 2em;
  border-top: .2em solid #0095AD;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline-story-blue:hover:after {
  right: 0;
}

/* Story purple underline */
#underline-story-purple {
  color: #8F3FCD;
  text-decoration: none;
  position: relative;
}

#underline-story-purple:link {
  text-decoration: none;
  color: #8F3FCD;
}

#underline-story-purple:after {
  border-radius: 2em;
  border-top: .2em solid #8F3FCD;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline-story-purple:hover:after {
  right: 0;
}

/* Green underline */
#underline-green {
  color: #6BE0BF;
  text-decoration: none;
  position: relative;
}

#underline-green:link {
  text-decoration: none;
  color: #6BE0BF;
}

#underline-green:after {
  border-radius: 2em;
  border-top: .2em solid #6BE0BF;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline-green:hover:after {
  right: 0;
}

/* Pink underline */
#underline-pink {
  color: #EAA3A2;
  text-decoration: none;
  position: relative;
}

#underline-pink:link {
  text-decoration: none;
  color: #EAA3A2;
}

#underline-pink:after {
  border-radius: 2em;
  border-top: .2em solid #EAA3A2;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline-pink:hover:after {
  right: 0;
}

/* Purple underline */
#underline-purple {
  color: #4F52C3;
  text-decoration: none;
  position: relative;
}

#underline-purple:link {
  text-decoration: none;
  color: #4F52C3;
}

#underline-purple:after {
  border-radius: 2em;
  border-top: .2em solid #4F52C3;
  content: "";
  position: absolute;
    right: 100%;
    bottom: 0em;
    left: 0;
  transition: right .4s cubic-bezier(0,.5,0,1);
}

#underline-purple:hover:after {
  right: 0;
}
